import React, {lazy, Suspense} from 'react';
import {Loader} from 'coresap';

import routingUtils from './app/utils/routing';
const Root = lazy(() => import('./app/pages/root/root.jsx'));

function App() {
    routingUtils.updateHash();

    if(document.referrer.indexOf('dam.sap.com') === -1 && !sessionStorage.getItem('referer')) {
        sessionStorage.setItem('referer', document.referrer);
    }

    return (
      <Suspense fallback={<div className='page-root--loader'><Loader /></div>}>
        <Root />
      </Suspense>
    )
}

export default App;
