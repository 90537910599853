'use strict';

import routes from '../reusable/constants/routes';
import restClientConstants from "../reusable/constants/rest_client";

export default {
    getParameterByName: function (name, relaxed) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#'+ (relaxed === true ? '\\?' : '') +']*)');
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    },

    getLastUrlParam: function(url) {
        const value = url.substring(url.lastIndexOf('/') + 1);
        return value;
    },

    transitionTo: function(path, query, newWindow, history) {
        if(!history) {
            alert('Push history in transitionTo as last parameter!');
            return;
        }
        const link = this._buildUrl(path, query);
        newWindow ? window.open(`${link}`, '_blank') : history.push(link);
    },

    _encodeQuery: function(data) {

        if (!data) {
            return '';
        }
        return Object.keys(data).map(function(key) {
            return [key, data[key]].map(encodeURIComponent).join('=');
        }).join('&');
    },

    _buildUrl: function(path, query) {
        const encodeQuery = this._encodeQuery(query);
        return path + (encodeQuery ? ('?' + encodeQuery) : '');
    },

    updateHash: function(){
        const hashRedirect = this.getParameterByName('h');
        if (!window.location.hash && hashRedirect) {
            const reg = new RegExp('\\|', 'gm');
            window.location.hash = hashRedirect.replace(reg, '/');
            const hashSearchMode = this.getParameterByName('mode');
            if(hashSearchMode){
                window.location.hash += '?mode=' + hashSearchMode.replace(reg, '/');
            }
        }
    },

    generateUrlWithParameter(url, key, value) {
        const baseUrl = url.split('?')[0],
            query = url.split('?')[1],
            urlQueryString = query ? ('?' + query) : '',
            newParam = key + '=' + value;
        let params = '?' + newParam;    
            

        // If the "search" string exists, then build params from it
        if (urlQueryString) {
            const updateRegex = new RegExp('([\?&])' + key + '[^&]*');
            const removeRegex = new RegExp('([\?&])' + key + '=[^&;]+[&;]?');

            if (typeof value === 'undefined' || value === null || value === '') { // Remove param if value is empty
                params = urlQueryString.replace(removeRegex, "$1");
                params = params.replace(/[&;]$/, "");

            } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
                params = urlQueryString.replace(updateRegex, "$1" + newParam);

            } else { // Otherwise, add it to end of query string
                params = urlQueryString + '&' + newParam;
            }
        }

        // no parameter was set so we don't need the question mark
        params = (params === '?' || !params || params === '?undefined') ? '' : params;

        return baseUrl + params;
    },

    removeUrlParameterByNameWithoutReload(param) {
        const url = new URL(window.location.href);
        url.searchParams.delete(param || "cs_ss");
        window.history.replaceState(null, null, url.toString());
    },

    setParamToUrl(key, value) {
        const newUrl = this.generateUrlWithParameter(window.location.href, key, value);
        window.history.replaceState(null, null, newUrl);
    },

    getContentStoreSavedSearchUrl(item) {
        if (item.folderId) {
            return routes.CS_SEARCH + '/' + item.folderType + '/' + item.folderId +'?ss=' + item.id;
        }

        return routes.CS_SEARCH + '/' + item.folderType +'?ss=' + item.id;
    },

    isPublicPageByRoute() {
        let isPublic = false;
        const path = window.location.href;
        const publicPathParts = ['/app/assets/', '/app/p/', '/app/e/'];

        publicPathParts.forEach((part)=>{
            if(path.indexOf(part) !== -1) {
                isPublic = true;
            }
        })

        return isPublic;
    },

    getRestClient() {
        return this.isPublicPageByRoute() ? restClientConstants.NO : restClientConstants.DGL_INAPP
    }
};
